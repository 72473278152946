<template>
	<div>
		<v-hover v-slot:default="{ hover }" open-delay="200" close-delay="200" :class="margin">
			<v-card :elevation="hover ? 16 : 2" width="374"
			        :class="(currentCardIndex !== undefined)
                && (cardIndex !== undefined)
                && currentCardIndex === cardIndex ? 'card-active':''"
			        flat
			>
				<v-row class="clickable-area"  @click="onCardClicked">
					<v-col cols="12" md="7" class="pr-0">
						<v-card-title class="pb-0 py-0 text-truncate">
							{{propertyUnit.property.name}}
						</v-card-title>

						<v-col cols="12" class="pt-0 pl-4">
							<p class="distance text-no-wrap">
								{{distanceToSchool}} mi from school
							</p>
						</v-col>

						<v-card-text :class="isMobile ? 'pt-0' : 'py-0'">
							<v-row>
								<v-col cols="6" class="py-0">
									<v-row>
										<v-col cols="7" class=" py-0 text-no-wrap">
											{{propertyUnit.number_of_bedrooms}}bds
										</v-col>
										<v-col cols="5" class="px-0 py-0">
											<v-avatar left tile size="20"><v-img :src="require('@/assets/icons/icon-bed.svg')"></v-img></v-avatar>
										</v-col>
									</v-row>
								</v-col>
								<v-col cols="6" class="py-0">
									<v-row>
										<v-col cols="6" class="px-0 py-0 text-truncate">
											{{propertyUnit.number_of_baths}}ba
										</v-col>
										<v-col cols="6" class="px-0 py-0">
											<v-avatar left tile size="20"><v-img :src="require('@/assets/icons/icon-bath.svg')"></v-img></v-avatar>
										</v-col>
									</v-row>
								</v-col>
							</v-row>
						</v-card-text>
					</v-col>
					<v-col cols="12" md="5">
						<v-card-title class="pb-0 py-0 clickable-area text-no-wrap" @click="onCardClicked">
							${{propertyUnit.price}}
						</v-card-title>

						<v-card-text class="clickable-area text-right pl-0 pr-6" :class="isMobile ? 'pt-0' : 'py-0'" @click="onCardClicked">
							<p class="text-truncate">
								<small>#{{propertyUnit.name}}</small>
							</p>

							<div>
								<v-tooltip bottom>
									<template v-slot:activator="{ on, attrs }">
										<a class="show-availability"
										   v-bind="attrs" v-on="on"
										   v-html="availableDate(propertyUnitAvailabilityDate)"
										   @click.prevent="showAvailabilities = true"
										></a>
									</template>
									<span>Last updated on: {{propertyUnit.last_availability_update | formatDate}}</span>
								</v-tooltip>
							</div>
						</v-card-text>
					</v-col>
				</v-row>


				<v-card-actions class="py-0 px-4">
					<v-row v-if="isMatches">
						<v-col cols="12" sm="8" class="py-0">
							<v-checkbox class="py-0 text-no-wrap" v-model="includeRoommates">
								<template v-slot:label>
									<p><small>Include Roommates?</small></p>									
								</template>
							</v-checkbox>
						</v-col>
						<v-col cols="12" sm="4" class="py-0 text-right">
							<v-btn color="primary" :block="isMobile"
							       @click="referProperty(propertyUnit)"
							       :loading="isProcessingReferral"
							>Refer</v-btn>
						</v-col>
					</v-row>
					<v-row v-if="!isMatches">
						<v-col cols="12" sm="7" class="text-no-wrap pl-3">
							<p class="referred-text" v-if="status.id !== 3">{{status.status_long}}</p>
							<div v-if="status.id === 3">
								<v-icon color="#FFB100">mdi-star</v-icon>
								<span class="referred-text">{{status.status_long}}</span>
							</div>
						</v-col>
						<v-col cols="12" sm="5" class="text-right" v-if="isReferred">
							<v-btn outlined color="primary"
							       :block="isMobile"
							       @click="removeReferredProperty"
							       :loading="isRemovingReferredProperty"
							>Remove</v-btn>
						</v-col>
					</v-row>
				</v-card-actions>
			</v-card>
		</v-hover>

		<v-dialog width="500" v-model="showAvailabilities">
			<v-card>
				<v-card-title class="d-flex justify-space-between pb-6">
					Property Unit Availabilities
					<v-hover>
						<v-btn  icon slot-scope="{ hover}" :elevation="hover ? 4 : 0" @click="showAvailabilities= false">
							<v-icon :color="hover ? 'primary' : ''">mdi-close</v-icon>
						</v-btn>
					</v-hover>
				</v-card-title>
				<v-card-text>
					<div v-if="propertyUnit.property_unit_availabilities && propertyUnit.property_unit_availabilities.length > 0">
						<div  v-for="item in propertyUnit.property_unit_availabilities" :key="item.id">
							<div class="d-flex justify-space-between pt-4">
								<p>Available Date: <strong>{{item.available_date | formatDate}}</strong></p>
								<p>Price: <strong>${{item.price > 0 ? item.price : propertyUnit.price}}</strong></p>
							</div>
							<v-divider></v-divider>
						</div>
					</div>
					<div v-else>
						<h3>No availabilities</h3>
					</div>
				</v-card-text>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
    import StudentService from "../../services/student.service";
    import moment from 'moment'

    export default {
        name: "StudentPropertyMatchCard",
        props: [
            'student',
            'propertyUnit',
            'margin',
            'type',
            'referredPropertyUnitId',
            'statusId',
            'cardIndex',
            'currentCardIndex',
            'parentId',
            'distanceToSchool',
            'index'
        ],
        data() {
            return {
                includeRoommates: 1,
                isProcessingReferral: false,
                isRemovingReferredProperty: false,
                isClicked: false,
	              showAvailabilities: false
            }
        },
        computed: {
            isMobile() {
                return this.$vuetify.breakpoint.name === 'xs'
            },
            isMatches() {
                return this.type === 'matches';
            },
            isReferred() {
                return this.type === 'referred';
            },
            school() {
                let school = [];
                this.$store.getters.getSchools.forEach(currentSchool => {
                    if (Number(this.student.school_id) === Number(currentSchool.id)) {
                        school = currentSchool
                    }
                });

                return school;
            },
            status() {
                let currentStatus = {};
                const referralStatuses = this.$store.getters.getReferralStatuses;
                referralStatuses.forEach(status => {
                    if (Number(status.id) === Number(this.statusId)) {
                        currentStatus = status;
                    }
                });

                return currentStatus
            },
		        propertyUnitAvailabilityDate() {
			        const unit = this.propertyUnit

			        if (!unit.property_unit_availabilities || unit.property_unit_availabilities.length === 0) {
				        return null
			        }

			        return unit.property_unit_availabilities.reduce((soonest, availability) => {
				        const availableDate = moment(availability.available_date)
				        const soonestDate = moment(soonest)

				        if (soonest === null || availableDate.isBefore(soonestDate)) {
					        return availability.available_date
				        }

				        return soonest
			        }, null)
		        }
        },
        methods: {
            referProperty: async function(propertyUnit) {
                this.isProcessingReferral = true;
                let hasErrors = false;
                const referredProperty = await StudentService.referStudentsToProperty(this.student.id, {
                    data: {
                        propertyId: propertyUnit.id,
                        includeRoommates: this.includeRoommates,
                        sender: 'agent'
                    }
                }).catch(() => {
                    hasErrors = true;
                });

                this.isProcessingReferral = false;
                if (hasErrors) {
                    return;
                }

                this.$emit('propertyReferred', referredProperty);
            },
            onCardClicked() {
               this.$emit('onCardClicked', {
                   cardIndex: this.cardIndex,
                   distanceToSchool: this.distanceToSchool,
                   propertyUnit: this.propertyUnit,
                   type: this.type,
                   statusId: this.statusId,
                   parentId: this.parentId,
                   index: this.index
               })
            },
            removeReferredProperty: async function() {
                this.isRemovingReferredProperty = true;
                await StudentService.removeReferredProperty(this.referredPropertyUnitId);
                this.isRemovingReferredProperty = false;

                this.$emit('removeReferredProperty', this.referredPropertyUnitId);
            },
		        availableDate(value) {
			        if (value === null) {
				        return "No Availability"
			        }

			        const today = moment()
			        const date = moment(value)

			        if (date.isBefore(today)) {
				        return "Available now"
			        }

			        const oneYearInFuture = today.add(1, 'y')

			        if (date.isSameOrAfter(oneYearInFuture)) {
				        return "Available <br>" + date.format("MMMM D YYYY")
			        }

			        return "Available <br>" + date.format("MMMM D")
		        },
        },
        filters: {
            capitalize(value) {
                return value.charAt(0).toUpperCase() + value.slice(1)
            },
			formatDate(date) {
				if (!date) return 'N/A'
				return moment.utc(date).local().format('l hh:mm A')
			}
        }
    }
</script>

<style scoped>
    .clickable-area {
        cursor: pointer;
    }

    .distance {
        color: grey
    }

    .referred-text {
        font-size: 10px;
    }
    .card-active {
        border: #2D76E3;
        border-style: solid;
    }

    .show-availability {
		    font-size: 12px;
    }
</style>